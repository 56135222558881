export const arrayParaString = (array,field = null) => {
  try {
    let result = "";
    if (array && array.length > 0) {
      if (field) {
        array.forEach(e => {
          result = result + e[field] + ", ";
        });
      } else {
        array.forEach(e => {
          result = result + e.value + ", ";
        });    
      }
    }
    return result.slice(0,result.length-2);
  } catch (e) {
    return "";
  }
}