<template>
  <div>
    <div class="loader">
        <b-spinner
            class="text-center justify-content-between"
            variant="warning"
            label="Loading..."></b-spinner>
        <!-- <v-icon scale="2" name="check" class="loaderCheck"/> -->
        <label class="concluido">{{mensagem}}</label>
    </div>       
  </div>
</template>

<script>
export default {
    props : ['mensagem']
}
</script>

<style lang="scss" scoped>
    @import "@/common/scss/breakpoints.scss";

    .concluido {
        @media (min-width: $medium-size) {
            grid-column: 3 / -1;
            align-self: center;
            padding-left: 0.5rem;
        }
    }

    .loader {
        @media (min-width: $medium-size) {
            grid-column: 1 / -1;
            align-self: center;
        }
        &Spinner {
            align-self: center;
        }
        &Check {
            color: #00cf2d;
        }
    }
</style>