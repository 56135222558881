import axios from 'axios';
import { API_GATEWAY } from './rotasServidor';

export const getLogProcessamentoNotas = async function(body) {
  return await axios.post(`${API_GATEWAY}/logs/notas`, body);
}

export const getLogLancamentoContab = async function(body) {
  return await axios.post(`${API_GATEWAY}/logs/lancamentos`, body);
}

export const getLogInventario = async function(body) {
  return await axios.post(`${API_GATEWAY}/logs/inventarios`, body);
}